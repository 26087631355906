.wrapper{
    max-width: 500px;

}

.body {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .subtitle {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #FBFDFE;
        margin-bottom: 0;
    }

    .block {
        display: flex;
        flex-direction: column;
        gap: 8px;
        &_headers {
            display: flex;
            gap: 8px;
            &_header {
                display: flex;
                gap: 6px;
                align-items: center;
                .cryptoImage {
                    width: 16px;
                    height: 16px;
                }
                .cryptoName {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16.5px;
                    color: #FFFFFF;
                    margin: 0;
                }
            }
        }
        &_description{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #A4B3CC;
            margin: 0;
        }
    }

    .button {
        padding: 14px;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        border-radius: 6px;
        background-color: #405071;
        border: none;

        &:hover {
            background-color: #4D5F7C;
        }
    }
}
.link {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #A4B3CC;
    text-decoration: underline;
}