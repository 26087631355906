.input-group-text,
.form-control,
.form-control[readonly],
.form-control:focus {
  color: lighten($body-color, 10);
  background: $sel-bg;
  border: 1px solid $scolor2;
  box-shadow: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input.form-control {
  min-width: 100px;
}

.form-control,
.form-control:focus {
  color: white;
}

.form-group {
  label {
    font-weight: 500;
    font-size: 0.8rem;
    color: lighten($sel-active-bg, 20);
  }

  input {
    font-weight: 400;
    font-size: 0.9rem;
  }

  .input-group-append {
    .btn {
      border-radius: 0 4px 4px 0;
    }
  }

  button {
    font-size: 0.9rem;
    font-weight: 500;
  }
}

.toggle {
  position: relative;
  margin: 2px;
  cursor: pointer;
  display: flex;
  white-space: nowrap;
  align-items: center;

  div {
    color: #d8dadf;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 0 8px;
    line-height: 21px;
    padding: 0 0 3px;
    user-select: none;
  }

  input[type="checkbox"] {
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }

  &:before {
    content: "";
    display: block;
    width: 38px;
    height: 20px;
    background: #d8dadf;
    border-radius: 25px;
  }

  span {
    display: block;
    width: 24px;
    height: 24px;
    background-color: #525c6e;
    border: 3px solid #d8dadf;
    border-radius: 12px;
    background: #525c6e;
    position: absolute;
    top: -2px;
    left: -2px;
    transition: left 0.15s ease-in-out;
  }

  input[type="checkbox"]:checked + span {
    left: 16px;
    background: $scolor3;
  }

  &.inverse {
    &:before {
      background: #525c6e;
    }

    span {
      background: $scolor2;

      &:after {
        color: #525c6e;
      }
    }

    input[type="checkbox"]:checked + &:after {
      background: #fff;
      color: #212529;
    }
  }
}

.form-control {
  height: 48px;
}

.form-element {
  .input-additions {
    position: relative;
    display: flex;
    font-size: 12px !important;
    justify-content: space-between;
    align-items: flex-start;

    .invalid-feedback {
      display: block;
      font-size: 0.875rem;
      margin: 0;
      bottom: -1rem;

      &-sum {
        position: relative;
        top: -3px;
      }
    }

    .caption {
      .input-sum-available {
        white-space: nowrap;
        text-align: right;
        user-select: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.input {
  input[type="password"],
  &-password input[type="text"] {
    padding-right: 64px !important;
  }

  input[type="text"],
  input[type="password"],
  input[type="number"] {
    background-color: $scolor2;
    border-color: transparent;
    border-radius: 6px;
    height: 48px;
    line-height: 48px;
    margin: 8px 0;
    max-width: 100%;
    width: 100%;

    &.is-invalid {
      border-color: #ff7a73 !important;
    }

    &::placeholder {
      color: #525c6e;
    }

    &.invalid {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff7a73' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff7a73' stroke='none'/%3e%3c/svg%3e") !important;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-repeat: no-repeat;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      border-color: #ff7a73 !important;
      padding-right: calc(1.5em + 0.75rem);
    }
  }

  input[type="password"].invalid,
  &-password input[type="text"].invalid {
    background-position: right 44px center;
  }
}

.input-password {
  position: relative;

  input {
    padding-right: 44px;
  }

  svg {
    color: $scolor3;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 16px;
    top: calc(50% - 10px);
    user-select: none;

    &:hover {
    }
  }

  &.active svg {
    color: #838fa3;
  }
}

.input-search {
  .input-group-text,
  .form-control,
  .form-control[readonly],
  .form-control:focus {
    background: #405071;
    border: 1px solid #405071;
    box-shadow: none;
    color: #bdc0c7;
    height: 40px;
  }

  .input-group-text {
    border-radius: 5px 0 0 5px;
  }

  .form-control {
    padding-left: 8px;
  }

  .form-control::placeholder {
    color: #bdc0c7;
  }

  .btn.btn-secondary {
    background: #405071;
    border: 1px solid #405071;
    border-radius: 0 5px 5px 0;
    color: #bdc0c7;

    svg {
      color: #bdc0c7;
    }

    &:hover,
    &:focus,
    &:active {
      background: #405071;
      border: 1px solid #405071;
      color: #bdc0c7;
    }
  }
}
