.wallet-modal {
  width: 500px;
  max-width: calc(100% - 8px);
  min-height: auto;

  .modal-content {
    min-height: 300px;
  }

  .btn-secondary.wallet-button {
    background: #40598e;
    border-radius: 5px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    height: 48px;
    padding: 0 48px;
    border: none;

    &:active,
    &:hover {
      background: #40598e;
      opacity: 0.8;
    }
  }

  .option-select {
    background: #000917;
    margin: 0 -1rem;
  }

  li.modal-nav-section {
    max-width: calc(100% / 3);
  }

  .alert {
    width: 100%;
    margin-top: 1rem;
  }

  .account-balance {
    font-size: 0.8rem;
    color: #838fa3;
    width: 100%;
    text-align: right;
    margin-top: 0.5rem;

    span {
      color: $sportbet;
    }
  }

  .btn-submit {
    background: $scolor4;
    padding: 14px 43px;
    border-radius: 6px;

    &.disabled {
      background-color: #66738d !important;
      background-image: none;
      color: #ffffff;
    }
  }

  .wallet-selector-item {
    width: 100%;
    display: flex;
    height: 50px;
    padding: 0.5rem 0.8rem;
    align-items: center;
    justify-content: space-between;
    img {
      height: 100%;
      aspect-ratio: 1;
      margin-right: 0.5rem;
    }
    .main-info {
      display: flex;
      height: 100%;
    }
    .amount-info.column-text {
      text-align: right;
      align-items: flex-end;
    }
    &--defaultCursor {
      cursor: default;
    }
  }
  .error-msg {
    color: $danger;
    font-size: 14px;
    padding-top: 5px;
  }
  .network-error {
    border: 1px solid #ff7a73;
    border-radius: 6px;
  }
  .warn {
    color: $warning;
    border-color: $warning;
  }
  .column-text {
    gap: 0.2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    line-height: 16px;
    > span:nth-child(2) {
      color: #a4b3cc;
    }
  }
  button.bt-dropdown-toggle.btn-secondary {
    background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    border: none;
    display: flex;
    width: 100%;
    padding: 0.5rem 0.8rem 0.5rem 0;
    border-radius: 6px;
    height: 50px;
    color: #d8dadf;
    font-size: 16px;
    font-weight: normal;
    align-items: center;
  }
  .dropdown-menu {
    background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    border-radius: 4px;
    border: none;
    max-height: 400px;
    overflow-y: auto;

    .wallet-selector-item {
      color: #d8dadf;
      background: transparent;
      border: none;

      &:hover,
      &:active,
      &.active {
        background: #40598e;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    width: auto;
  }
}

.assets-overview {
  &-modal {
    width: 500px;
    max-width: calc(100% - 8px);
    min-height: auto;

    .modal-content {
      .modal-body {
        padding-bottom: 0;
      }
    }
  }

  &__assets {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;
    justify-content: space-between;
  }

  &-item {
    border-radius: 6px;
    margin: 4px 0;
    box-sizing: border-box;
    width: 100%;
    min-height: 2rem;
    display: flex;

    &__image {
      height: 100%;
      aspect-ratio: 1;
      margin-right: 0.5rem;
    }

    &__buttons {
      margin-bottom: 1rem;
      display: flex;
      gap: 0.5rem;
      width: 100%;
      justify-content: space-between;
      button {
        width: 100%;
      }
    }
    &__name {
      display: flex;
      gap: 2px;
      flex-direction: column;
      justify-content: center;
      span:first-child {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        max-height: 16px;
      }
      span:nth-child(2) {
        font-size: 12px;
        line-height: 14px;
        color: $gray2;
        white-space: nowrap;
      }
    }

    &__summary {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      width: auto;
      gap: 12px;

      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    &__title {
      span {
        display: block;
      }
      font-size: 14px;
      line-height: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #e5e5e5;
      margin: 0 0 12px;

      img {
        width: 20px;
        height: 20px;
        margin: 0 8px 0 0;
      }
    }

    &__amount {
      font-size: 18px;
      margin-left: auto;
      line-height: 21px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include media-breakpoint-down(sm) {
        width: 100%;
        flex-wrap: wrap;
      }

      &-value {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @include media-breakpoint-down(xs) {
          /* width: 100%; */
        }
      }

      .wallet__fiat-amount {
        margin: 0;
      }
    }

    &__actions {
      display: flex;
      height: 100%;
      align-items: center;
    }

    $action-col-space: 8px;

    &__actionsGrid {
      display: flex;
      flex-wrap: wrap;
      width: auto;
      margin-left: -$action-col-space;
      padding-bottom: 20px;

      @include media-breakpoint-down(xs) {
        padding-bottom: 0;
      }
    }

    &__action {
      min-height: 48px; // btn-lg
      min-width: 160px;
      width: 100%;
      // btn styles
      font-size: 1rem; // 16px
      line-height: 2rem;
      color: $btnfont2 !important;
      border: 0 !important;
      box-shadow: none !important;
      border-radius: 4px;

      &.btn-primary {
        background-color: #48629a;
        background-image: linear-gradient(180deg, #48629a 0%, #40598e 100%);
        color: #d8dadf;

        &:hover,
        &:focus,
        &:active {
          background-image: linear-gradient(180deg, #4d69a6 0%, #40598e 100%);
          color: #fbfdfe;
        }
      }

      &.btn-secondary {
        background-color: #354361;
        background-image: linear-gradient(180deg, #354361 0%, #303d58 100%);
        color: #d8dadf;

        &:hover,
        &:focus,
        &:active {
          background-image: linear-gradient(152.08deg, #3a4a6b 0%, #303d58 100.47%);
          color: #fbfdfe;
        }
      }

      &.w-full {
        min-width: calc(100% - #{$action-col-space});
      }
    }
  }
}

.wallet__fiat-amount {
  font-size: 14px;
  line-height: 16px;
  margin: 0 0 0 8px;
  color: #838fa3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:before {
    content: "≈";
    display: inline-block;
    margin: 0 2px 0 0;
  }
}

.wallet-layout {
  margin-top: 1rem;

  .form-group {
    margin-bottom: 0 !important;
  }

  .alert.alert-danger {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 100%;
      /* margin-left: 2rem; */
    }
  }

  input {
    &,
    &:hover,
    &:active {
      border-radius: 5px;
      border-color: rgb(30, 38, 55);
      outline: none;
      box-shadow: none;
    }
  }

  label {
    font-weight: normal;
  }

  &__text {
    padding: 0 4px 0 0;
    margin: 1.2rem 0 0.4rem 0;
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
    font-size: 1rem;
    line-height: 1.2rem;
    display: flex;
    align-items: center;
    color: #a4b3cc;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem 0;
    gap: 0.5rem;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #a4b3cc;
  }
}

.wallet-info {
  font-size: 0.85rem;
  color: $sportbet;
  margin: 16px 0 0 0;
}

.wallet-transfer {
  &__buttons {
    display: flex;
    align-items: flex-end;
    gap: 0.8rem;
    > div {
      width: 100%;
      &:nth-child(2) {
        width: auto;
        button {
          background: #1e2637;
          padding: 4px 12px;

          &:not(:disabled):active,
          &:focus {
            background: #1e2637;
          }
          &:hover,
          &:active {
            background: #252f44;
          }
        }
      }
    }
    &__button {
      background: $scolor4;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 4px 1rem;
      min-height: 40px;
      border-radius: 6px;
    }
  }
}

.wallet-back-button {
  margin-right: auto;
  border-radius: 50%;
  color: #d8dadf;
  background: $scolor4;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.2rem;
  text-align: center;

  &:hover {
    background: $scolor6;
    color: #d8dadf;
  }
}

.social-link {
  position: relative;
  max-width: 276px;
  height: 50px;
  border-radius: 5px;
  margin: 12px auto 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: none;
    color: #fff;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.alert-enter {
  animation: slideDown 0.3s ease-out forwards;
}

.alert-exit {
  animation: slideUp 0.3s ease-in forwards;
}
