.settings-popup {
  max-width: 624px;

  .modal-body {
    padding-bottom: 0;
    border-radius: 0 0 6px 6px;
  }

  .radio-tabs {
    margin-bottom: 24px;
  }

  input[type="password"],
  input[type="password"]:hover,
  input[type="password"]:active,
  input[type="password"]:focus,
  input[type="password"].invalid,
  input[type="text"],
  input[type="text"]:hover,
  input[type="text"]:active,
  input[type="text"]:focus,
  input[type="text"].invalid,
  input[type="number"],
  input[type="number"]:hover,
  input[type="number"]:active,
  input[type="number"]:focus,
  input[type="number"].invalid {
    background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    border-radius: 6px;
    font-size: 16px;
    line-height: 19px;
    color: #838fa3;
    height: 48px !important;
    padding: 0 16px;
    margin: 0 !important;
    border: 1px solid #3f4f70;
    position: relative;

    &::placeholder {
      color: #838fa3 !important;
    }
  }

  .input-password svg {
    color: #838fa3;
  }

  input[type="password"],
  input[type="text"],
  input[type="number"] {
    &.invalid {
      background-image: none !important;
      background-position: initial !important;
      background-repeat: repeat !important;
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%) !important;
      border: 1px solid #ff7a73;
    }
  }

  .toggle {
    margin: 0 2px;
  }

  .toggle span {
    width: 16px;
    height: 16px;
    top: 2px;
    left: 2px;
    border: none;
    background: #303d58;
  }

  .toggle input[type="checkbox"]:checked + span {
    left: 15px;
    background: #73e698;
  }

  .toggle:before {
    background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    width: 34px;
  }
}

.settings-group {
  padding: 0 0 16px;

  h3 {
    text-transform: uppercase;
    margin: 0 0 12px;
    font-weight: normal;
    font-size: 16px;
    color: #a1a6b0;
  }

  &-subset {
    padding: 8px;
    background: #1e2637;
    border-radius: 5px;
    margin: 8px 0;

    p.caption {
      font-size: 12px;
    }
  }

  &.fullwidth {
    background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
    margin: 0 -16px;
    padding: 16px;
    width: calc(100% + 32px);

    .settings-group-subset {
      background: #303d58;
    }
  }

  .icon {
    width: 22px;
    height: 22px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px 0 0;
    border-radius: 50%;
    color: #fff;

    svg {
      font-size: 12px;
      margin: 0;
    }

    &.icon-google {
      background: #db4437;
    }

    &.icon-facebook {
      background: #4267b2;
      align-items: flex-end;

      svg {
        font-size: 17px;
      }
    }

    &.icon-twitter {
      background: #1da1f2;
    }
  }
}

.settings {
  &__expand {
    border-radius: 4px;
    margin: 0 0 8px 0;

    &-title {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
      border-radius: 4px;
      color: #d8dadf;
      font-size: 16px;
      line-height: 16px;
      padding: 0 20px 0 12px;
      height: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      user-select: none;

      > span {
        margin: 0 0 0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-content {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
      border-radius: 0px 0px 6px 6px;
      padding: 5px 20px 18px 36px;
    }

    &.open &-title {
      border-radius: 4px 4px 0 0;
    }

    &.inner {
      background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);

      &.light {
        background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);

        .toggle span {
          background: #d8dadf;
        }

        .toggle:before {
          background: #303d58;
        }
      }
    }

    &.inner &-title {
      background: none;
      padding: 0 20px 0 18px;

      > span {
        margin: 0;
      }
    }

    &.inner &-content {
      background: none;
      padding: 5px 15px 18px 20px;
    }

    &-loader {
      margin: 0 0 0 auto;
    }

    &-error {
      margin: 0 0 0 auto;
      color: #ff7a73;
    }
  }

  &__panel {
    background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
    padding: 18px 15px 18px 20px;
    border-radius: 6px;

    &.light {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    }
  }

  &__saved,
  &__warning,
  &__error {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
    margin: 0 0 0 12px;
    overflow: hidden;

    svg {
      font-size: 20px;
    }

    span {
      font-size: 16px;
      margin-left: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__saved {
    color: #73e698;
  }

  &__error {
    color: #ff7a73;
  }

  &__form-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .settings__saved {
      margin: 0 0 0 20px;
    }
  }

  &__form-error-message {
    margin-bottom: 1rem;

    .settings__error {
      margin: 0;
      align-items: flex-start;

      svg {
        margin-top: 3px;
      }

      span {
        white-space: normal;
      }
    }
  }
}

.settings__sign-in-opt-ins-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > svg {
    margin: 0 8px 0 0;
    width: 22px !important;
  }
}

.tfa-settings {
  display: flex;
  flex-wrap: wrap;

  &__caption {
    color: #d8dadf;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    margin: 0 0 12px;
  }

  &__qr {
    background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
    width: 160px;
    height: 160px;
    padding: 12px;
    border-radius: 6px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__activate-form {
    width: calc(100% - 180px);
    margin: 0 0 20px 20px;

    @media screen and (max-width: 520px) {
      width: 100%;
      margin: 20px 0;
    }
  }

  &__apps {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  &__app {
    width: calc(50% - 10px);
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    a {
      color: #73e698;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    @media screen and (max-width: 520px) {
      width: 100%;
    }
  }

  &__disclaimer {
    color: #73e698;
    text-align: right;
    width: 100%;
    margin: 16px 0;
    font-size: 14px;
  }

  &__form {
    width: 100%;

    .btn-main {
      width: 100%;
    }
  }
}

.settings-editable-field {
  position: relative;

  .form-group {
    margin: 0;
  }

  &.disabled {
    input {
      padding-right: 40px !important;
    }
  }

  &__status {
    position: absolute;
    right: 12px;
    user-select: none;
    top: 12px;
  }

  .caption {
    text-align: right;
    min-width: 100%;
    width: 100%;
    margin: 6px 0 0 0;
  }
}

.settings__tfa-recovery-link {
  display: block;
  color: #d8dadf;
  margin: 8px 0 0 0;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
}

.settings-newsletter-subscribe {
  .checkbox__input {
    margin-top: 4px;
  }
}
