.wrapper{
    max-width: 500px;
}

.body {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .subtitle {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #FBFDFE;
        margin-bottom: 0;
    }

    .link {
        color: #73e698;
        text-decoration: underline;
    
        &:hover {
          text-decoration: none;
        }
    }
}